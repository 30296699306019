import React, { useCallback, useContext, useState } from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from '@material-ui/core/Button';
import noop from 'lodash/noop';
import { ModalCloseBtn } from '../statelessComponents/Buttons/ButtonsInModals';

import CustomModal from '../Modals/Modal';

import s from './VerifyTwoFaModal.scss';
import messages from './messages';
import ContextProvider from '../../context';
import OtpTwoFaForm from './VerificationMethods/Otp/OtpTwoFaForm';
import { TWO_FA_TYPES } from '../../constants/twoFa';
import VerificationSelectionModal from './VerificationSelectionModal/VerificationSelectionModal';
import OtpTwoFaEmail from './VerificationMethods/Email/OtpTwoFaEmail';

const VerifyTwoFaModal = props => {
  const {
    onClose,
    onSubmit,
    isActionConfirmation,
    action,
    isLoading,
    verificationAccount,
    onCloseAfterSubmit,
    onSubmitFailedDueToExpiration,
  } = props;

  const { fetch } = useContext(ContextProvider);

  const [twoFaType, setTwoFaType] = useState(TWO_FA_TYPES.GOOGLE_AUTHENTICATOR);

  const [isVerificationSelectShown, setVerificationSelectShown] = useState(
    false,
  );

  const [
    isSendingEmailVerificationCode,
    setIsSendingEmailVerificationCode,
  ] = useState(false);

  const handleChooseAnotherVerificationMethodClick = useCallback(() => {
    setVerificationSelectShown(true);
  }, [setVerificationSelectShown]);

  const handleChooseAnotherVerificationMethodClose = useCallback(() => {
    setVerificationSelectShown(false);
  }, [setVerificationSelectShown]);

  const sendEmailVerification = useCallback(async () => {
    try {
      setIsSendingEmailVerificationCode(true);
      const res = await fetch('/api/auth/otp/email-confirmation', {
        method: 'POST',
        body: JSON.stringify({
          action,
        }),
      });
      if (!res.ok) {
        const resJson = await res.json();

        if (resJson.message) {
          throw new Error(resJson.message);
        }
        throw new Error(resJson);
      }
      setIsSendingEmailVerificationCode(false);
    } catch (err) {
      setIsSendingEmailVerificationCode(false);
    }
  }, [setIsSendingEmailVerificationCode, fetch, action]);

  const handleChangeVerificationMethod = useCallback(
    async newMethod => {
      setTwoFaType(newMethod);

      if (newMethod === TWO_FA_TYPES.EMAIL) {
        await sendEmailVerification();
      }

      handleChooseAnotherVerificationMethodClose();
    },
    [
      setTwoFaType,
      handleChooseAnotherVerificationMethodClose,
      sendEmailVerification,
    ],
  );

  return (
    <div className={s.container}>
      <Typography variant="h5" component="h1" id="verify-2fa-modal-title">
        <FormattedMessage {...messages.modalTitle} />
      </Typography>

      {twoFaType === TWO_FA_TYPES.GOOGLE_AUTHENTICATOR && (
        <OtpTwoFaForm
          isActionConfirmation={isActionConfirmation}
          onSubmit={onSubmit}
          action={action}
          verificationAccount={verificationAccount}
          onCloseAfterSubmit={onCloseAfterSubmit}
          onSubmitFailedDueToExpiration={onSubmitFailedDueToExpiration}
        />
      )}

      {twoFaType === TWO_FA_TYPES.EMAIL && (
        <OtpTwoFaEmail
          isActionConfirmation={isActionConfirmation}
          onSubmit={onSubmit}
          action={action}
          handleResendCode={sendEmailVerification}
          isSendingEmailVerificationCode={isSendingEmailVerificationCode}
          verificationAccount={verificationAccount}
          onCloseAfterSubmit={onCloseAfterSubmit}
          onSubmitFailedDueToExpiration={onSubmitFailedDueToExpiration}
        />
      )}

      <Button
        className={s.verificationMethodBtn}
        variant="outlined"
        onClick={handleChooseAnotherVerificationMethodClick}
        disabled={isLoading}
      >
        <FormattedMessage {...messages.chooseAnotherTwoFaMethodBtnLabel} />
      </Button>

      <CustomModal
        aria-describedby="two-fa-method-select-modal"
        isOpen={isVerificationSelectShown}
        width="30%"
      >
        <VerificationSelectionModal
          twoFaType={twoFaType}
          handleTwoFaMethodChange={handleChangeVerificationMethod}
          onClose={handleChooseAnotherVerificationMethodClose}
        />
      </CustomModal>

      <ModalCloseBtn
        fitMobileLandscapeFullWidth
        id="certificate-collection-modal-close-btn"
        onClick={onClose}
      />
    </div>
  );
};

VerifyTwoFaModal.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseAfterSubmit: PropTypes.func.isRequired,
  isActionConfirmation: PropTypes.bool,
  action: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  verificationAccount: PropTypes.string.isRequired,
  onSubmitFailedDueToExpiration: PropTypes.func,
};

VerifyTwoFaModal.defaultProps = {
  isActionConfirmation: false,
  onSubmitFailedDueToExpiration: noop,
};

export default compose(injectIntl, withStyles(s))(VerifyTwoFaModal);
