/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fieldInputPropTypes } from 'redux-form';

import {
  RichUtils,
  EditorState,
  getDefaultKeyBinding,
  convertFromRaw,
  convertToRaw,
  Modifier,
} from 'draft-js/lib/Draft.js';
import withStyles from 'isomorphic-style-loader/withStyles';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import createImagePlugin from 'draft-js-image-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import { stateFromHTML } from 'draft-js-import-html';

import focusStyles from 'draft-js-focus-plugin/lib/plugin.css';
import linkifyStyles from 'draft-js-linkify-plugin/lib/plugin.css';
import alinmnetStyles from 'draft-js-alignment-plugin/lib/plugin.css';

import ImageAdd from './ImageAdd';
import AddLink from './AddLink';

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });

const pluginsForImage = [
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
];

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

function getSelectionText(editorState) {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  return currentContentBlock.getText().slice(start, end);
}

export const emptyContent = {
  entityMap: {},
  blocks: [
    {
      text: '',
      key: 'foo',
      type: 'unstyled',
      entityRanges: [],
    },
  ],
};

export const emptyContentState = convertFromRaw(emptyContent);

const StyleButton = ({ onToggle, style, active, label }) => {
  let className = 'RichEditor-styleButton';
  if (active) {
    className += ' RichEditor-activeButton';
  }

  const handle = e => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <span
      className={className}
      onMouseDown={handle}
      onKeyDown={handle}
      role="button"
      tabIndex={0}
    >
      {label}
    </span>
  );
};
StyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

const BlockStyleControls = ({ editorState, onToggle, blockTypes }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {blockTypes.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

BlockStyleControls.propTypes = {
  onToggle: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,  // eslint-disable-line
  blockTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      style: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const InlineStyleControls = ({ editorState, onToggle, inlineStyles }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {inlineStyles.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  onToggle: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,  // eslint-disable-line
  inlineStyles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      style: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

@withStyles(focusStyles)
@withStyles(alinmnetStyles)
@withStyles(linkifyStyles)
class RichEditor extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    wrapperClass: PropTypes.string,
    inlineStyles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
      }),
    ),
    blockTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
      }),
    ),
    uploadImageApiEndpointUrl: PropTypes.string,
    plugins: PropTypes.array, // eslint-disable-line
    editorRef: PropTypes.func,
    disableUploadImage: PropTypes.bool,
    showAddLink: PropTypes.bool,
    ...fieldInputPropTypes,
  };

  static defaultProps = {
    uploadImageApiEndpointUrl: '',
    wrapperClass: '',
    label: '',
    disableUploadImage: false,
    showAddLink: false,
    inlineStyles: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
      // { label: 'Monospace', style: 'CODE' },
    ],
    blockTypes: [
      { label: 'H1', style: 'header-one' },
      { label: 'H2', style: 'header-two' },
      { label: 'H3', style: 'header-three' },
      { label: 'H4', style: 'header-four' },
      { label: 'H5', style: 'header-five' },
      { label: 'H6', style: 'header-six' },
      { label: 'Blockquote', style: 'blockquote' },
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
      { label: 'Code Block', style: 'code-block' },
    ],
    plugins: [],
    editorRef: () => null,
  };

  static contextTypes = {
    cloudfrontUrl: PropTypes.string,
  };

  constructor(props, context) {
    super(props);
    // here we create the empty state
    let editorState = EditorState.createWithContent(emptyContentState);
    // if the redux-form field has a value
    if (props.input.value) {
      const value = _.cloneDeep(props.input.value);

      const entityMap = {};
      _.each(value.entityMap, (item, key) => {
        entityMap[key] = item;
        if (item.type === 'IMAGE') {
          let prefix = '';
          if (!item.data.src.includes(context.cloudfrontUrl)) {
            prefix = `${context.cloudfrontUrl}`;
          }
          entityMap[key].data.src = `${prefix}${(item.data.src || '').replace(
            '/cloudfront',
            '',
          )}`;
        }
      });
      value.entityMap = entityMap;

      // convert the editorState to whatever you'd like
      editorState = EditorState.createWithContent(convertFromRaw(value));
    }
    // Set the editorState on the state
    this.state = {
      editorState,
      isEdited: false,
    };
  }

  onChange = editorState => {
    const { input } = this.props;

    const raw = convertToRaw(editorState.getCurrentContent());
    const entityMap = {};
    _.each(raw.entityMap, (value, key) => {
      if (value.type === 'LINK') {
        // handle pasted links
        value.data.explicit = true; // eslint-disable-line no-param-reassign
        value.data.target = '_blank'; // eslint-disable-line no-param-reassign
      }
      entityMap[key] = value;
    });
    raw.entityMap = entityMap;
    // converting to the raw JSON on change
    input.onChange(raw);

    // compare prevState and newState to determine selection
    // Set it on the state
    this.setState(prevState => ({
      editorState,
      isEdited:
        prevState.editorState.getCurrentContent() ===
          editorState.getCurrentContent() && getSelectionText(editorState),
    }));
  };

  onTab = e => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };

  handleAddLink = linkUrl => {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();

    if (!linkUrl) return;

    // creating Entity
    const contentStateWithEntity = currentContent.createEntity(
      'LINK',
      'MUTABLE',
      { url: linkUrl, explicit: true, target: '_blank' },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // updating currentContent property in editorState
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    this.onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey,
      ),
    );
  };

  handleRemoveLink = () => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }
    this.onChange(RichUtils.toggleLink(editorState, selection, null));
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = inlineStyle => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    );
  };

  handlePastedText = (text, html, editorState) => {
    const { blockMap } = stateFromHTML(html || text);
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap,
    );
    this.onChange(EditorState.push(editorState, newState, 'insert-fragment'));
    return true;
  };

  mapKeyToEditorCommand(e) {
    switch (e.keyCode) {
      case 9: {
        // TAB
        const newEditorState = RichUtils.onTab(
          e,
          this.state.editorState,
          4 /* maxDepth */,
        );
        if (newEditorState !== this.state.editorState) {
          return this.onChange(newEditorState);
        }
        break;
      }
      default:
        return getDefaultKeyBinding(e);
    }

    return true;
  }

  render() {
    const { editorState, isEdited } = this.state;

    const {
      input,
      label,
      wrapperClassName,
      inlineStyles,
      blockTypes,
      meta: { error, touched },
      helperText,
      uploadImageApiEndpointUrl,
      plugins,
      editorRef,
      disableUploadImage,
      showAddLink,
      ...restProps
    } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    const labelNode = label ? (
      <Typography variant="subtitle1" gutterBottom>
        {label}
      </Typography>
    ) : null;

    return (
      <div className={wrapperClassName}>
        {labelNode}
        <div
          className={classNames(
            'RichEditor-root',
            touched && error ? 'RichEditor-root-error' : undefined,
          )}
        >
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
            blockTypes={blockTypes}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
            inlineStyles={inlineStyles}
          />
          <div className="RichEditor-toolbar">
            {showAddLink && (
              <AddLink
                editorState={editorState}
                handleAddLink={this.handleAddLink}
                handleRemoveLink={this.handleRemoveLink}
                disabled={!isEdited}
              />
            )}
            {!!uploadImageApiEndpointUrl && (
              <ImageAdd
                editorState={editorState}
                onChange={this.onChange}
                modifier={imagePlugin.addImage}
                apiEndpointUrl={uploadImageApiEndpointUrl}
                disabled={disableUploadImage}
              />
            )}
          </div>
          <div className={className}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              handlePastedText={this.handlePastedText}
              onTab={this.onTab}
              {...input}
              onBlur={() => input.onBlur(input.value)}
              onChange={this.onChange}
              spellCheck
              plugins={
                uploadImageApiEndpointUrl
                  ? [...plugins, ...pluginsForImage]
                  : plugins
              }
              {...restProps}
              ref={node => {
                if (node && editorRef && editorRef.current) {
                  editorRef(node);
                }
              }}
            />
            <AlignmentTool />
          </div>
        </div>
        <FormHelperText error={touched && error}>
          {(touched && error) || helperText}
        </FormHelperText>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <RichEditor {...props} editorRef={ref} />
));
