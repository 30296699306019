import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';

const TextField = ({ input, meta: { touched, invalid, error }, ...custom }) => (
  <MUITextField
    {...input}
    {...custom}
    error={touched && invalid}
    helperText={(touched && error) || custom.helperText}
  />
);

TextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
};

export default TextField;
