import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import messages from './messages';
import { ModalCloseBtn } from '../../statelessComponents/Buttons/ButtonsInModals';
import s from './VerificationSelectionModal.scss';
import { TWO_FA_TYPES } from '../../../constants/twoFa';

const VerificationSelectionModal = ({
  twoFaType,
  handleTwoFaMethodChange,
  onClose,
  intl: { formatMessage },
}) => {
  const handleMethodChange = newMethod => () =>
    handleTwoFaMethodChange(newMethod);

  return (
    <div>
      <div>
        <Typography variant="h5" component="h1" gutterBottom>
          <FormattedMessage {...messages.modalTitle} />
        </Typography>
        <List className={s.methodsList}>
          <ListItem
            className={s.activeListItem}
            button
            onClick={handleMethodChange(TWO_FA_TYPES.GOOGLE_AUTHENTICATOR)}
            selected={twoFaType === TWO_FA_TYPES.GOOGLE_AUTHENTICATOR}
          >
            <ListItemText
              primary={formatMessage(messages.googleAuthenticatorListItemText)}
              classes={{
                primary: s.listItemTitleTextPrimary,
              }}
            />
          </ListItem>
          <ListItem
            className={s.activeListItem}
            button
            onClick={handleMethodChange(TWO_FA_TYPES.EMAIL)}
            selected={twoFaType === TWO_FA_TYPES.EMAIL}
          >
            <ListItemText
              primary={formatMessage(messages.emailListItemText)}
              classes={{
                primary: s.listItemTitleTextPrimary,
              }}
            />
          </ListItem>
        </List>
      </div>
      <ModalCloseBtn
        fitMobileLandscapeFullWidth
        id="choose-2fa-verification-method-close-btn"
        data-test="choose-2fa-verification-method-close-btn"
        onClick={onClose}
      />
    </div>
  );
};

VerificationSelectionModal.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  twoFaType: PropTypes.string.isRequired,
  handleTwoFaMethodChange: PropTypes.func.isRequired,
};

export default compose(injectIntl, withStyles(s))(VerificationSelectionModal);
